import React, { useEffect, useState } from "react";
import "../WaterReservoir/WaterReservoir.css";
import { HiUserGroup } from "react-icons/hi";
import { FaCheck, FaTag } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from "../../Assets/images/water-images/water-img-4.png";
import waterimg1 from "../../Assets/images/water-images/water-img-1.png";
import waterimg2 from "../../Assets/images/water-images/water-img-2.png";
import { toast } from "react-toastify";
import axios from "axios";
import { API_DOMAIN } from "../../utils/GlobalConst";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RxCrossCircled } from "react-icons/rx";
import { CgCloseO } from "react-icons/cg";
import jQuery from "jquery";


function copyText(element) {
  console.log(jQuery(element).text());
  var $copyText = jQuery(element).text();
  var button = jQuery("#btn_copy_address");
  navigator.clipboard.writeText($copyText).then(
    function () {
      var originalText = button.text();
      console.log(originalText, button.text());
      button.html("Copied!");
      button.addClass("clicked");
      setTimeout(function () {
        button.html(originalText);
        button.removeClass("clicked");
      }, 750);
    },
    function () {
      button.html("Error");
    }
  );
}

function WaterReservoir() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const navigate = useNavigate();
  const [approxDate, setApproxDate] = useState(tomorrow);
  const tomorrowtwo = new Date();
  tomorrowtwo.setDate(tomorrowtwo.getDate() + 1);
  const [proceedDate, setProceedDate] = useState(tomorrowtwo);
  const [fileURL, setFileURL] = useState("");
  const [openUploadimage, setOpenUploadimage] = useState(false);
  const [profileImg, setProfileImg] = useState([]);
  const [tokenName, setTokenName] = useState("");
  const [price, setPrice] = useState("");
  const [location, setLocation] = useState("");
  const [nameofwater, setNameofwater] = useState("");
  const [description, setDescription] = useState("");
  const [numoftokenwater, setNumoftokenwater] = useState("");
  const [managerTokenPercentage, setManagerTokenPercentage] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerWallet, setManagerWallet] = useState("");
  const [marketingTokenPercentage, setMarketingTokenPercentage] = useState("");
  const [marketingEntityName, setMarketingEntityName] = useState("");
  const [marketingEntityWallet, setMarketingEntityWallet] = useState("");
  const [custodianTokenPercentage, setCustodianTokenPercentage] = useState("");
  const [custodianName, setCustodianName] = useState("");
  const [custodianWallet, setCustodianWallet] = useState("");
  const [generalSaleTokenPercentage, setGeneralSaleTokenPercentage] =
    useState("");
  const [generalSaleWallet, setGeneralSaleWallet] = useState("");
  const [popup, setPopup] = useState(false);
  const [contractAddress, setContractAddress] = useState("");

  const handleProfileImgUpload = () => {
    // console.log(img)
    var API_URL = API_DOMAIN + "image/upload";
    var formData = new FormData();
    formData.append("att_file", profileImg[0]);
    console.log(formData);

    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, formData, headerConfig)
      .then(function (response) {
        console.log("Profile Img Upload", response);
        setFileURL(response.data.data.filePath);
        console.log(response.data.data.filePath);
        if (response.status === 200) {
          setOpenUploadimage(false);
          // handleUpdateProfileurl(response?.data?.data?.Location);
          // handleCloseProfileImagePopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          // toast.error(error.response.data.message);
          setTimeout(() => {
            // setLoading(false);
            // handleCloseProfileImagePopup();
            setProfileImg("");
          });
        }
      })
      .then(function () {});
  };
  const TokenMetaData = () => {
    var API_URL = "https://water.moramba.io/api/contract/deploy";
    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      name: nameofwater,
      symbol: "TNT",
      totalSupply: 50,
      owner: "0xAdA2f4B700d59bc47F48c96E1D468Ba727d2ea70",
      price: Number(price),
      tokenUri: fileURL,
      mintNow: true,
      location: location,
      tokenname: tokenName,
      description: description,
      peroftokenmanager: managerTokenPercentage,
      managername: managerName,
      walletaddressmanager: managerWallet,
      peroftokenmarketingentity: marketingTokenPercentage,
      marketingentityname: marketingEntityName,
      walletaddressmarketingentity: marketingEntityWallet,
      peroftokencustodian: custodianTokenPercentage,
      custodianname: custodianName,
      walletaddresscustodian: custodianWallet,
      peroftokengeneralsale: generalSaleTokenPercentage,
      walletaddressgeneralsale: generalSaleWallet,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create Token", response);
        if (response.status === 200) {
          setPopup(true);
          setContractAddress(response.data.contractAddress);
          console.log(response.data.contractAddress);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className=" nav-wrapper">
            <div className="logo-container">
              <Link to="/all-water-reservoir">
                <img src={logo} alt="" className="brand-logo" />
              </Link>
            </div>

            <div className="">
              <nav>
                <input className="hidden" type="checkbox" id="menuToggle" />
                <label className="menu-btn" for="menuToggle">
                  <div className="menu "></div>
                  <div className="menu"></div>
                  <div className="menu"></div>
                </label>
                <div className="nav-container">
                  <ul className="nav-tabs">
                    <li className="nav-tab">
                      <Link to="/all-water-reservoir" className="nav-link">
                        All
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        {/* <div className="container "> */}
        <section id="subheader" className="p-0">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Tokenize a New Water Reservoir</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        <section aria-label="section">
          <div className="container">
            <div className="row fadeIn">
              <div className="col-lg-7 offset-lg-1">
                <div
                  id="form-create-item"
                  className="form-border"
                  // method="post"
                  // action="email.php"
                >
                  <div className="field-set">
                    <h5>Upload file</h5>
                    <button
                      className="btn-main"
                      onClick={() => setOpenUploadimage(true)}
                    >
                      Upload File
                    </button>

                    <div className="spacer-40"></div>

                    <h5>Select method</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li className="active">
                          <span>
                            <i className="fa fa-tag">
                              <FaTag />
                            </i>
                            Fixed price
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="fa fa-users">
                              <HiUserGroup />
                            </i>
                            Open for bids
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content">
                        <div id="tab_opt_1">
                          <h5>Price</h5>
                          <input
                            type="text"
                            name="item_price"
                            id="item_price"
                            className="dropdown fullwidth"
                            placeholder="Enter price for one item (TNT)"
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>

                        <div id="tab_opt_2"></div>
                      </div>
                    </div>

                    <div className="spacer-20"></div>

                    <h5>Choose location</h5>
                    <p className="p-info">
                      This is the location where your water reservoir will
                      appear.
                    </p>

                    <div
                      id="item_collection"
                      className="dropdown fullwidth mb5"
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <select className="btn-selector w-100 profile-select">
                        Select Location
                        <option value="abstraction">USA</option>
                        <option value="patternlicious">Canada</option>
                        <option value="skecthify">India</option>
                        {/* <option value="cartoonism">Cartoonism</option>
                        <option value="virtuland">Virtuland</option>
                        <option value="papercut">Papercut</option> */}
                      </select>
                    </div>
                    <h5>NH Water Reservoir</h5>
                    <input
                      type="text"
                      placeholder="Enter name of Water Reservoir"
                      className="dropdown fullwidth"
                      onChange={(e) => setNameofwater(e.target.value)}
                    />

                    <div className="spacer-20"></div>
                    <h5>Token name </h5>
                    <input
                      type="text"
                      placeholder="Enter token name"
                      className="dropdown fullwidth"
                      onChange={(e) => setTokenName(e.target.value)}
                    />

                    <div className="spacer-20"></div>

                    <h5>Description of Water Reservoir</h5>
                    <textarea
                      data-autoresize
                      className="dropdown fullwidth"
                      placeholder="Enter description of Water Reservoir"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>

                    <div className="spacer-20"></div>

                    <h5>Number of tokens for this Water Reservoir</h5>
                    <input
                      type="text"
                      className="dropdown fullwidth"
                      placeholder="Enter number of tookens for this Water Reservoir"
                      onChange={(e) => setNumoftokenwater(e.target.value)}
                    />

                    <div className="spacer-single"></div>

                    <div className="row mx-4">
                      <div className="col-lg-12">
                        <h5>Percentage(%) of token to reservoir manager</h5>
                        <input
                          type="text"
                          className="dropdown fullwidth"
                          placeholder="Enter percentage(%) of token to reservoir manager"
                          onChange={(e) =>
                            setManagerTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Name of manager</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter name of  manager"
                            onChange={(e) => setManagerName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6">
                          <h5>Wallet address of manager</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter wallet address of manager"
                            onChange={(e) => setManagerWallet(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="spacer-single"></div>

                      <div className="col-lg-12">
                        <h5>Percentage(%) of token to marketing entity</h5>
                        <input
                          type="text"
                          className="dropdown fullwidth"
                          placeholder="Enter percentage(%) of token to marketing entity"
                          onChange={(e) =>
                            setMarketingTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Name of marketing entity</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter name of  marketing entity"
                            onChange={(e) =>
                              setMarketingEntityName(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <h5>Wallet address of marketing entity</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter wallet address of marketing entity"
                            onChange={(e) =>
                              setMarketingEntityWallet(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="spacer-single"></div>

                      <div className="col-lg-12">
                        <h5>Percentage(%) of token to custodian</h5>
                        <input
                          type="text"
                          className="dropdown fullwidth"
                          placeholder="Enter percentage(%) of token to custodian"
                          onChange={(e) =>
                            setCustodianTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Name of custodian</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter name of  custodian"
                            onChange={(e) => setCustodianName(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6">
                          <h5>Wallet address of custodian</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter wallet address of custodian"
                            onChange={(e) => setCustodianWallet(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="spacer-single"></div>

                      <div className="col-lg-12">
                        <h5>Percentage(%) of token for general sale</h5>
                        <input
                          type="text"
                          className="dropdown fullwidth"
                          placeholder="Enter percentage(%) of token to custodian"
                          onChange={(e) =>
                            setGeneralSaleTokenPercentage(e.target.value)
                          }
                        />
                      </div>
                      <div className="row mx-4 mt-3">
                        <div className="col-lg-6">
                          <h5>Wallet address for general sale</h5>
                          <input
                            type="text"
                            className="dropdown fullwidth"
                            placeholder="Enter wallet address  for general sale"
                            onChange={(e) =>
                              setGeneralSaleWallet(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="spacer-20"></div>
                    <div className="d-flex flex-wrap gap-3">
                      <div>
                        <h5>Approx Date of Distribution </h5>
                        <DatePicker
                          // className="dropdown fullwidth dateInputSty h_50"
                          className="dropdown fullwidth datepickerSty"
                            minDate={tomorrow}
                            selected={approxDate}
                          dateFormat="dd MMM,yyyy"
                            onChange={(date) => [setApproxDate(date)]}
                        />
                      </div>
                      <div>
                        <h5>
                          Distribution of proceeds to Token owners as of Date
                        </h5>
                        <DatePicker
                          //   className="dropdown fullwidth dateInputSty h_50"
                          className="dropdown fullwidth datepickerSty"
                            minDate={tomorrowtwo}
                            selected={proceedDate}
                          dateFormat="dd MMM,yyyy"
                            onChange={(date) => [setProceedDate(date)]}
                        />
                      </div>
                    </div>
                    {/* <input
                      type="text"
                      className="dropdown fullwidth"
                      placeholder="Enter number of tookens for this painting"
                      // onChange={(e) => setTotalTokenOfPainting(e.target.value)}
                    /> */}
                    <br />
                    <input
                      type="button"
                      id="submit"
                      className="btn-main mt-4"
                        onClick={TokenMetaData}
                      value="Create Token"
                    />
                    <div className="spacer-single"></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5>Preview item</h5>
                <div className="nft__item">
                  <div
                    className=""
                    // data-year="2022"
                    // data-month="6"
                    // data-day="16"
                    // data-hour="8"
                  ></div>
                  <div className="author_list_pp">
                    <Link href="#">
                      <img className="lazy" src={waterimg1} alt="" />
                      <i className="fa fa-check">
                        <FaCheck />
                      </i>
                    </Link>
                  </div>
                  <div className="nft__item_wrap">
                    <Link href="#">
                      <img
                        src={fileURL === "" ? waterimg2 : fileURL}
                        className="lazy nft__item_preview"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="nft__item_info">
                    <Link href="#" className="LinkText">
                      {/* <h4>{paintingName}</h4> */}
                    </Link>
                    {/* <div className="nft__item_click">
                      <span></span>
                    </div> */}
                    <div className="nft__item_price">
                      {/* <span>1/20</span> */}
                    </div>
                    {/* <div className="nft__item_action">
                      <Link href="#" className="LinkText">
                        Place a bid
                      </Link>
                    </div>
                    <div className="nft__item_like">
                      <i className="fa fa-heart">
                        <FaRegHeart />
                      </i>
                      <span>50</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* </div> */}
        {popup === true ? (
          <>
            <Dialog
              fullScreen={fullScreen}
              open={popup}
              id="MainDiv"
              className="signout-modal"
              onClose={() => setPopup(false)}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title" className="text-end">
                <div className="d-flex justify-content-between">
                  <h3 className="text-center">
                  Water Reservoir contract created successfully!!
                  </h3>{" "}
                  <CgCloseO
                    className="closeIconSty"
                    onClick={() => [setPopup(false)]}
                  />
                </div>

                <Divider />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <div className="d-flex justify-content-start gap-3">
                    <div>
                      <span className="text-white">
                        {" "}
                        contract address:{" "}
                        <span id="contractaddress">
                          {" "}
                          {contractAddress}{" "}
                        </span>{" "}
                      </span>
                      <button
                        id="btn_copy_address"
                        title="Copy Text"
                        className="me-3"
                        onClick={() => copyText("#contractaddress")}
                      >
                        Copy
                      </button>
                    </div>
                  </div>

                  <p className="text-center">
                    {" "}
                    <p className="text-white">view contract</p>{" "}
                    <Link
                      to={`https://tntmoney.explorer.caldera.xyz/address/${contractAddress}`}
                      target="_blank"
                    >
                      here{" "}
                    </Link>{" "}
                  </p>
                </DialogContentText>
                <Divider />
              </DialogContent>
              <div className="d-flex justify-content-center gap-4 mb-3">
                <button
                  className="popup_btn_yes"
                  onClick={() => [navigate("/all-water-reservoir")]}
                >
                  Done
                </button>
              </div>
            </Dialog>
          </>
        ) : (
          <></>
        )}
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={openUploadimage}
        onClose={() => setOpenUploadimage(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-center"
        ></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="d-flex justify-content-between mt-2">
              <h5>Upload file</h5>
              <RxCrossCircled
                className="cross-icon text-white"
                onClick={() => setOpenUploadimage(false)}
              />
            </div>

            <div className="d-create-file">
              <p>PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
              <input
                type="file"
                className="text-white"
                onChange={(e) => [setProfileImg(e.target.files)]}
              />
            </div>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center gap-3 mb-3">
            <button
              className="btn-main cancel-btn"
              onClick={() => setOpenUploadimage(false)}
            >
              Cancel
            </button>
            <button className="btn-main" onClick={handleProfileImgUpload}>
              Save
            </button>
          </div>
        </>
      </Dialog>
    </>
  );
}

export default WaterReservoir;
