import React, { useEffect, useState } from "react";
import { FaCheck, FaSearch } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import $ from "jquery";
import waterimg1 from "../../Assets/images/water-images/water-img-1.png";
import waterimg2 from "../../Assets/images/water-images/water-img-2.png";
import logo from "../../Assets/images/water-images/water-img-4.png";
import { Link } from "react-router-dom";
import "../WaterReservoir/WaterReservoir.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
const ethers = require("ethers");

function dropdown(e) {
  var obj = $(e + ".dropdown");
  var btn = obj.find(".btn-selector");
  var dd = obj.find("ul");
  var opt = dd.find("li");

  obj
    .on("mouseenter", function () {
      dd.show();
      $(this).css("z-index", 1000);
    })
    .on("mouseleave", function () {
      dd.hide();
      $(this).css("z-index", "auto");
    });

  opt.on("click", function () {
    dd.hide();
    var txt = $(this).text();
    opt.removeClass("active");
    $(this).addClass("active");
    btn.text(txt);
  });
}

function AllWaterReservoir() {
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);

  const getData = () => {
    var API_URL = "https://water.moramba.io/api/contract/all";
    // let headerConfig = {
    //   headers: {
    //     authorization: "bearer " + sessionStorage.getItem("token"),
    //   },
    // };
    axios
      .get(API_URL)
      .then((response) => {
        console.log("get all data ", response.data.data);
        var res = response.data.data;
        console.log(res);
        setAllData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getData();
    // buytoken()
  }, []);

  const buytoken = async (fileid, contractAddress, qty, price) => {
    try {
      const contractAbi = allData[0].abi;
      
      // Request account access
      const accounts = await window.ethereum.request({ 
        method: 'eth_requestAccounts' 
      });
      
      // Create provider and signer
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      
      // Create contract instance with proper parameters
      const contract = new ethers.Contract(
        contractAddress,
        contractAbi,
        signer
      );

      // Convert price to proper format
      const priceInWei = ethers.parseUnits(price.toString(), 18);
      
      // Call the contract method with proper value parameter
      const txResponse = await contract.buyMinted(qty, {
        value: priceInWei,
        gasLimit: 300000 // Add reasonable gas limit
      });

      // Wait for transaction confirmation
      const receipt = await txResponse.wait();
      
      console.log("Transaction successful:", receipt.hash);
      toast.success("Purchase successful!");
      
      // Refresh data after successful purchase
      getData();
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error(error.message || "Transaction failed. Please try again.");
    }
  };

  const handleNetworkSwitch = async (networkName, fileid, contractAddress, qty, price) => {
    try {
      if (!window.ethereum) {
        toast.error("Please install MetaMask to make purchases");
        alert("Please install MetaMask to make purchases");
        return;
      }

      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      
      if (parseInt(chainId, 16) === 7754096) {
        await buytoken(fileid, contractAddress, qty, price);
      } else {
        // await changeNetwork({ networkName });
        // Add slight delay before buying to allow network switch to complete
        setTimeout(async () => {
          await buytoken(fileid, contractAddress, qty, price);
        }, 1000);
      }
    } catch (error) {
      console.error("Network switch failed:", error);
      toast.error("Failed to switch network. Please try again.");
      alert("Failed to switch network. Please try again.");
    }
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className=" nav-wrapper">
            <div className="logo-container">
              <Link to="/all-water-reservoir">
                <img src={logo} alt="" className="brand-logo" />
              </Link>
            </div>

            <div className="">
              <nav>
                <input className="hidden" type="checkbox" id="menuToggle" />
                <label className="menu-btn" for="menuToggle">
                  <div className="menu "></div>
                  <div className="menu"></div>
                  <div className="menu"></div>
                </label>
                <div className="nav-container">
                  <ul className="nav-tabs">
                    <li className="nav-tab">
                      <Link to="/water-reservoir" className="nav-link">
                        Create
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        {/* <div className="" id=""> */}
        {/* <!-- section begin --> */}
        <section id="subheader" className="pt-0">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>All Water Reservoir</h1>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="col-md-12 text-center">
          <h2>All Water Reservoir</h2>
        </div> */}

        {/* <!-- section close --> */}

        {/* <!-- section begin --> */}
        <section aria-label="section">
          <div className="container">
            <div className="row  fadeIn">
              <div className="col-lg-12">
                <div className="items_filter">
                  <form
                    action="blank.php"
                    className="row form-dark"
                    id="form_quick_search"
                    method="post"
                    name="form_quick_search"
                  >
                    <div className="col text-center">
                      <input
                        className="dropdown fullwidth"
                        id="name_1"
                        name="name_1"
                        placeholder="search here..."
                        type="text"
                      />{" "}
                      <div className="clearfix"></div>
                    </div>
                  </form>

                  <div
                    id="items_type"
                    className="dropdown"
                    onMouseEnter={(e) => dropdown("#items_type")}
                  >
                    <a href="#" className="btn-selector">
                      All Location <RiArrowDropDownLine />
                    </a>
                    <ul>
                      <li className="active">
                        <span>All Location</span>
                      </li>
                      <li>
                        <span>USA</span>
                      </li>
                      <li>
                        <span>Canada</span>
                      </li>
                      <li>
                        <span>India</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- nft item begin --> */}
              {/* <Card /> */}
              {allData.map((v) => {
                return (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="nft__item">
                        <div className="nft__item_wrap">
                          <img
                            src={
                              v.nft.tokenUri === "" ? waterimg1 : v.nft.tokenUri
                            }
                            className="lazy cardimgstyle nft__item_preview"
                            alt=""
                          />
                        </div>
                        <div className="nft__item_info">
                          <Link
                            //   to={`/item-details/${cardinfo.imageUpload_id}`}
                            className="LinkText"
                          >
                            <h4>{v.nft.name}</h4>
                          </Link>
                          <div className="nft__item_click">
                            <span></span>
                          </div>
                          <div className="nft__item_price">
                            {v.nft.price}{" "}
                            {v.nft.symbol === "WTR" ? "TNT" : v.nft.symbol}
                            {/* <span>/{v.nft.totalSupply}</span> */}
                          </div>
                          <div>Available 20</div>
                          <div>Total volume {v.nft.totalSupply} </div>
                          <div className="nft__item_action">
                            <button
                              className=" btn-main  mt-2 "
                              onClick={() => [
                                // handleBuy(v._id)
                                handleNetworkSwitch(
                                  "caldera",
                                  v._id,
                                  v.contractAddress,
                                  1,
                                  v.nft.price
                                ),
                                console.log(v._id),
                              ]}
                            >
                              Buy Now{" "}
                            </button>
                            {/* <p>{message}</p> */}
                          </div>
                          <div className="nft__item_like d-flex"></div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="nft__item">
                        <div className="nft__item_wrap">
                          <img
                            src={waterimg2}
                            className="lazy cardimgstyle nft__item_preview"
                            alt=""
                          />
                        </div>
                        <div className="nft__item_info">
                          <Link
                            //   to={`/item-details/${cardinfo.imageUpload_id}`}
                            className="LinkText"
                          >
                            <h4>Water Reservoir 2</h4>
                          </Link>
                          <div className="nft__item_click">
                            <span></span>
                          </div>
                          <div className="nft__item_price">2 TNT</div>
                          <div className="nft__item_action">
                            <button className="LinkText text-black fw-bold">
                              Buy Now{" "}
                            </button>
                          </div>
                          <div className="nft__item_like d-flex"></div>
                        </div>
                      </div>
                    </div> */}
                  </>
                );
              })}

              {/* <div className="col-md-12 text-center">
                <a href="#" id="loadmore" className="btn-main  fadeInUp lead">
                  Load more
                </a>
              </div> */}
            </div>
          </div>
        </section>
        {/* </div> */}
      </div>
    </>
  );
}

export default AllWaterReservoir;
