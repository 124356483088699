import "./App.css";
import "../src/Assets/css/coloring.css";
import "../src/Assets/css/de-black.css";
import "../src/Assets/css/style.css";
import "../src/Assets/css/bootstrap.min.css";
import "../src/Assets/css/plugins.css";
import "../src/Assets/css/coloring-gradient.css";
import "../src/Assets/css/bootstrap.rtl.min.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Components/Homepage/HomePage";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import DetailPage from "./Components/DetailPage/DetailPage";
import ConnectWallet from "./Components/ConnectWallet/ConnectWallet";
import CreateToken from "./Components/CreateToken/CreateToken";
import Explore from "./Components/Explore/Explore";
import Profile from "./Components/Profile/Profile";
import CreatorProfile from "./Components/Creator/CreatorProfile";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import VerifyPage from "./Components/VerifyPage/VerifyPage";
import GoToTop from "./Components/GoToTop/GoToTop";
import AuthGard from "./utils/AuthGard";
import CreateDigitalCopy from "./Components/CreateToken/CreateDigitalCopy";
// import TermsConditions from "./Components/TermsConditions/TermsConditions";
// import PrivacyPolicy from "./Components/TermsConditions/PrivacyPolicy";
import WaterReservoir from "./Components/WaterReservoir/WaterReservoir";
import AllWaterReservoir from "./Components/WaterReservoir/AllWaterReservoir";
import WaterLogin from "./Components/WaterReservoir/WaterLogin";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/water-reservoir" element={<WaterReservoir />} />
          <Route path="/all-water-reservoir" element={<AllWaterReservoir />} />
          <Route path="/waterlogin" element={<WaterLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-page" element={<VerifyPage />} />
          {/* <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

          <Route path="/register" element={<Register />} />
          <Route path="/item-details/:imageID" element={<DetailPage />} />
          <Route
            path="/connect-wallet"
            element={<AuthGard Component={ConnectWallet} />}
          />
          <Route
            path="/create-token"
            element={<AuthGard Component={CreateToken} />}
          />
          <Route
            path="/create-digital-copy"
            element={<AuthGard Component={CreateDigitalCopy} />}
          />
          <Route path="/explore" element={<Explore />} />
          <Route path="/profile" element={<AuthGard Component={Profile} />} />
          <Route path="/creator-profile/:userid" element={<CreatorProfile />} />
        </Routes>
      </BrowserRouter>
      <GoToTop />
    </>
  );
}

export default App;
