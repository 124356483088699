import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import "../Login/Login.css";
import axios from "axios";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import { API_DOMAIN } from "../../utils/GlobalConst";
import logo from "../../Assets/images/water-images/water-img-4.png";

function Login() {
  const navigate = useNavigate("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [email, setEmail] = useState("");
  const [passsword, setPasssword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const [loginBtn, setLoginBtn] = useState(false);
  const [openloginEmailInfo, setOpenloginEmailInfo] = useState(false);

  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };
    const dummyUser = {
    email: "test@gmail.com",
    password: "Test@123"
  };
  const LoginValidationHandler = () => {
    var isValidLogin = true;

    if (!email === "mailto:test@gmail.com") {
      isValidLogin = false;
      setEmailErr("*Please Enter Email Or Phone Number!");
    }
    if (!passsword === "Test@123") {
      isValidLogin = false;
      setPassErr("*Please Enter Password!");
    }
    setLoginBtn(false);
    return isValidLogin;
  };

  // const loginHandler = () => {
  //   if (LoginValidationHandler()) {
  //   }
  // };
  
  const loginHandler = () => {
    if (email === "test@gmail.com" && passsword === "Test@123") {
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("passsword", passsword);
      setTimeout(()=>{
        navigate("/all-water-reservoir");
      })
    }
    else{
      alert("Please Enter Valid Email and Password")
    }
  };
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (loginBtn) {
        return;
      }
      loginHandler();
    }
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className=" nav-wrapper">
            <div className="logo-container">
              <Link to="/all-water-reservoir">
                <img src={logo} alt="" className="brand-logo" />
              </Link>
            </div>

            <div className="">
              <nav>
                <input className="hidden" type="checkbox" id="menuToggle" />
                <label className="menu-btn" for="menuToggle">
                  <div className="menu "></div>
                  <div className="menu"></div>
                  <div className="menu"></div>
                </label>
                <div className="nav-container">
                  {/* <ul className="nav-tabs">
                    <li className="nav-tab">
                      <Link to="/water-reservoir" className="nav-link">
                        Create
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="no-bottom no-top" id="content" onKeyPress={keyHandler}>
        <div id="top"></div>

        <section className="full-height relative no-top no-bottom pt-5 mt-4 pb-5 mb-5">
          <div className="overlay-gradient t50 p-0">
            <div className="center-y relative">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-4 offset-lg-4 fadeIn bg-color"
                    data-wow-delay=".5s"
                  >
                    <h3 className="text-center">Water Reservoir</h3>
                    <div className="padding40 d-border">
                      <h3 className="mb10">Sign In</h3>
                      {/* <p>
                        Login using an existing account or create a new account{" "}
                        <Link to="/register" className="LinkText">
                          here<span></span>
                        </Link>
                        .
                      </p> */}
                      <div
                        name="contactForm"
                        id="contact_form"
                        className="form-border"
                        // method="post"
                        // action="blank.php"
                      >
                        <div className="field-set">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={(e) => [
                              setEmail(e.target.value),
                              setEmailErr(""),
                              setLoginErr(""),
                            ]}
                          />
                          <span className="starsty">{emailErr}</span>
                        </div>
                        <div className="field-set mt-3">
                          <div className="loc-group d-flex position-relative">
                            <input
                              type={passVisibility ? "text" : "password"}
                              name="password"
                              id="password"
                              className="form-control"
                              placeholder="password"
                              onChange={(e) => [
                                setPasssword(e.target.value),
                                setPassErr(""),
                                setLoginErr(""),
                              ]}
                            />
                            <span className="pass-show-eye">
                              <button
                                className="reg-eye"
                                onClick={(e) => changeVisibility(e)}
                              >
                                {passVisibility === true ? (
                                  <BsEyeFill />
                                ) : (
                                  <BsEyeSlashFill />
                                )}
                              </button>
                            </span>
                          </div>
                          <span className="starsty">{passErr}</span>
                        </div>&nbsp;
                        {/* <p className="forgotlink">
                          <Link to={"/forgot-password"} className="LinkText">
                            Forgot Password?
                          </Link>
                        </p> */}

                        <div className="field-set">
                          <button
                            // type="submit"
                            // id="send_message"
                            // value="Submit"
                            onClick={loginHandler}
                            className="btn btn-main btn-fullwidth color-2"
                          >
                            Submit
                          </button>
                        </div>
                        <b>
                          {" "}
                          <center className="mt-2">
                            <span className="starsty mt-2">{loginErr}</span>
                          </center>
                        </b>
                        {/* <p className="text-center fs-10 mt-1">
                          <Link to={"/verify-page"} className="LinkText">
                            Having Trouble Sign In?
                          </Link>
                        </p> */}
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
